@import './boostrap.bundler';
@import '../fonts/fonts.utility';

/**
Global Styling should be kept at a MINIMUM. React and Bootstrap do NOT go well with each other.
Any additional contributions to this file will only further complicate the issue.
 */

body {
  background: var(--bs-body-background);
}

@include media-breakpoint-down(md) {
  section.hero .content img {
    position: absolute;
    top: 50%;
    transform: translateY(-25%) scaleX(-1) scale(1.5);
    z-index: -1;
    right: -3em;
  }

  .hide-section-image-md img.section-image {
    display: none;
  }

  .btn-meetup, .btn-get-involved {
    min-width: 10em;
  }

  section#about {
    height: 50vh;
  }
}

@include media-breakpoint-up(md) {
  section.events {
    .orange-fruit {
      border-radius: 0;
    }
  }
}


section.contact img.section-image {
  min-width: unset;
  min-height: unset;
}

// offset to account for fixed header
section {
  scroll-margin-top: 20vh;
}

// Meetup button styles. Note #e51937 -> hsl = hsl(351, 80%, 50%)
.btn-meetup,
.btn-get-involved,
.btn-slack,
.btn-eventbrite
{
  @include button-variant(hsl(47, 46.3%, 92%), hsl(47, 46.3%, 92%), color-contrast(hsl(47, 46.3%, 92%)));
}


// Fonts
@include font-face("Lato", "../fonts/Lato/Lato-Bold", $font-weight-bold, null, "ttf");
@include font-face("Lato", "../fonts/Lato/Lato-Regular", $font-weight-normal, null, "ttf");
@include font-face("Lato", "../fonts/Lato/Lato-Light", $font-weight-light, null, "ttf");
